<template>
    <div class="fristcon" v-if="infoList">
      <div class="fristcon-tit">
        {{projactNanme}}_{{ historyname }}
      </div>
      <div class="fristcon-cen flex">
        <!-- 顶部左边 -->
        <div class="fristcon-cen-left">
          <div class="fristcon-cen-left-tit">
            {{titname}}
          </div>
         <div class="btnarr">
            <div class="fristcon-cen-left-btn flex align-cen">
            <div class="text">SP</div>
            <div class="num ma-auto" @click="tocomment('SP', '#1799cd',0)">{{infoList[node].SP }}</div>
          </div>
           <div class="fristcon-cen-left-btn flex align-cen">
            <div class="text">PV</div>
            <div class="num ma-auto" @click="tocomment('PV', '#0de0ac',1)">{{infoList[node].PV }}</div>
          </div>
           <div class="fristcon-cen-left-btn flex align-cen">
            <div class="text">AV</div>
            <div class="num ma-auto" @click="tocomment('AV', '#ec163e',2)">{{infoList[node].AV }}</div>
          </div>
        </div>
        <div class="btnarr1">
          <div class="btnarr1-btn"
          :style="{ background: infoList[node].RM==1  ? '#0ef7ff' : '' , color: infoList[node].RM==1  ? '#25495e' : ''}"
           @click="chaneStu(1, 'RM', node)"
          >自动</div>
          <div class="btnarr1-btn"
          :style="{ background: infoList[node].RM==0  ? '#0ef7ff' : '' , color: infoList[node].RM==0  ? '#25495e' : ''}"
           @click="chaneStu(0, 'RM', node)"
          >手动</div>
          <div class="btnarr1-btn"
          :style="{ background: infoList[node].RM == 3 || infoList[node].RM == 4  ? '#0ef7ff' : '' , color: infoList[node].RM == 3 || infoList[node].RM == 4  ? '#25495e' : ''}"
           @click="chaneStu(3, 'RM', node)"
          >跟踪</div>
          <div class="btnarr1-btn"
          @click="toIpt(infoList[node].AD, '点击按钮', 'AD', node)"
          >{{ infoList[node].AD ? "反作用" : "正作用" }}</div>
        </div>
         </div>
         <!-- 顶部曲线图 -->
         <div class="fristcon-cen-cen">
           <div id="rts_Chart" v-loading="loading" :option="option"></div>
         </div>
         <div class="fristcon-cen-bot">
           <div class="typedata" >
              <div class="typedatabox" style="color: #1799cd" v-if="echarShow[0]">
                <div class="flex">设定值
                </div>
                <div>Y[{{rightarr[0] ? rightarr[0].data : infoList[node].SP }}]</div>
                <div>Y[{{ name[0] ? name[0] : historyname + "_SP" + charAtlet }}]</div>
              </div>
              <div class="typedatabox typedatabox1" style="color: #0de0ac" v-if="echarShow[1]">
                <div>测量值</div>
                <div>Y[{{ rightarr[1] ? rightarr[1].data : infoList[node].PV }}]</div>
                <div>Y[{{ name[1] ? name[1] : historyname + "_PV" + charAtlet }}]</div>
              </div>
              <div class="typedatabox" style="color: #ec163e" v-if="echarShow[2]">
                <div>总输出</div>
                <div>Y[{{rightarr[2] ? rightarr[2].data : infoList[node].AV}}]</div>
                <div>Y[{{ name[2] ? name[2] : historyname + "_AV" + charAtlet}}]</div>
              </div>
              <div class="typedatabox" style="color:#f2c334" v-if="echarShow[3]">
                      <div>PID输出</div>
                      <div>Y[{{rightarr[3] ? rightarr[3].data : infoList[node].AV_P}}]</div>
                       <div>Y[{{name[3] ? name[3] : historyname + "_AV_P" + charAtlet}}]</div>
                  </div>
                  <div class="typedatabox" style="color:#ec24ea" v-if="echarShow[4]">
                      <div>RSF输出</div>
                      <div>Y[{{rightarr[4] ? rightarr[4].data : infoList[node].AV_R}}]</div>
                       <div>Y[{{name[4] ? name[4] : historyname + "_AV_R" + charAtlet }}]</div>
                  </div>
                    <div class="typedatabox" style="color:#34f0f2" v-if="echarShow[5]">
                      <!-- fe771d -->
                      <div>观测器静态输出</div>
                      <div>Y[{{rightarr[5] ? rightarr[5].data : infoList[node].AV_GC}}]</div>
                       <div>Y[{{name[5] ? name[5] : historyname + "_AV_GC" + charAtlet}}]</div>
                  </div>
                   <div class="typedatabox" style="color:#FF6347" v-if="echarShow[6]">
                      <div>观测器输出</div>
                      <div>Y[{{rightarr[6] ? rightarr[6].data : infoList[node].AV_J}}]</div>
                       <div>Y[{{name[6] ? name[6] : historyname + "_AV_J" + charAtlet}}]</div>
                  </div>
                    <div class="typedatabox" style="color:#1662ec" v-if="echarShow[7]">
                      <div>CD输出</div>
                      <div>Y[{{rightarr[7] ? rightarr[7].data : infoList[node].AV_C}}]</div>
                       <div>Y[{{name[7] ? name[7] : historyname + "_AV_C" + charAtlet}}]</div>
                  </div>
                   <div class="typedatabox" style="color:#FFC0CB" v-if="echarShow[8]">
                      <div>观测器动态输出</div>
                      <div>Y[{{rightarr[8] ? rightarr[8].data : infoList[node].AV_D}}]</div>
                       <div>Y[{{name[8] ? name[8] : historyname + "_AV_D" + charAtlet}}]</div>
                  </div>
                
           </div>
         </div>
      </div>
      <div class="fristcon-cen-btn">
        <div class="btn-f flex">
          <div class="btn-f-btn" @click="serchDt(10)" :class="{current:active == 10}">{{dtname}}</div>
          <div class="btn-f-btn" @click="serchHis(1,6,'up')" :class="{current:active == 6}">前翻一页</div>
            <div class="btn-f-btn" @click="serchHis(0.5,7,'up')" :class="{current:active == 7}">前翻半页</div>
             <div class="btn-f-btn" @click="serchHis(0.5,8,'down')" :class="{current:active == 8}">后翻半页</div>
              <div class="btn-f-btn" @click="serchHis(1,9,'down')" :class="{current:active == 9}">后翻一页</div>
        </div>
      </div>
      <div class="fristcon-bot">
        <div class="fristcon-bot-row1 flex">
          <div class="row">
            <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">PVMD</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].PVMD" placement="top">
              <div class="num" @click="toIpt(infoList[node].PVMD, '测量值量程下限', 'PVMD', node)"> {{ infoList[node].PVMD }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">

                <div class="text">PVMU</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].PVMU" placement="top">
              <div class="num" @click="toIpt(infoList[node].PVMU, '测量值量程上限', 'PVMU', node)"> {{ infoList[node].PVMU }}</div>
                </el-tooltip>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">MD</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].MD" placement="top">
              <div class="num" @click="toIpt(infoList[node].MD, '输出控制量程下限', 'MD', node)"> {{ infoList[node].MD }}</div>
                </el-tooltip>
            </div>
             <div class="list-num list-num1 flex">
                <div class="text">MU</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].MU" placement="top">
              <div class="num" @click="toIpt(infoList[node].MU, '输出控制量程上限', 'MU', node)"> {{ infoList[node].MU }}</div>
               </el-tooltip>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">OUTB</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].OUTB" placement="top">
              <div class="num" @click="toIpt(infoList[node].OUTB, '控制输出下限，该值必须在MD和MU之间', 'OUTB', node)"> {{ infoList[node].OUTB }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">OUTT</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].OUTT" placement="top">
              <div class="num" @click="toIpt(infoList[node].OUTT, '控制输出上限，该值必须在MD和MU之间', 'OUTT', node)"> {{ infoList[node].OUTT }}</div>
               </el-tooltip>
            </div>
            </div>
              <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">ORL</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].OutRL" placement="top">
              <div class="num" @click="toIpt(infoList[node].OutRL, '输出最小幅度限幅，该值为MU-MD的百分比', 'OutRL', node)"> {{ infoList[node].OutRL }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">RTH</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].RTH" placement="top">
              <div class="num" @click="toIpt(infoList[node].RTH, '输出最速率限幅，该值为MU-MD的百分比', 'RTH', node)"> {{ infoList[node].RTH }}</div>
               </el-tooltip>
            </div>
            </div>
          </div>
          <div class="row1">
            <div class="row1-top">
                 <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text" >AV_P</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].AV_P" placement="top">
              <div class="num" style="color:#f2c334"  @click="tocomment('AV_P', '#f2c334',3)"> {{ infoList[node].AV_P }}</div>
              </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text" >AV_R</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].AV_R" placement="top">
              <div class="num" style="color:#ec24ea"  @click="tocomment('AV_R', '#ec24ea',4)"> {{ infoList[node].AV_R }}</div>
               </el-tooltip>
            </div>
            </div>
               <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">AV_GC</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].AV_GC" placement="top">
              <div class="num" style="color:#0dd9e0" @click="tocomment('AV_GC', '#0dd9e0',5)"> {{ infoList[node].AV_GC }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text" >AV_J</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].AV_J == '-0.00' ? '0.00' : infoList[node].AV_J" placement="top">
              <div class="num" style="color:#FF6347" @click="tocomment('AV_J', '#FF6347',6)"> {{ infoList[node].AV_J == "-0.00" ? "0.00" : infoList[node].AV_J}}</div>
                </el-tooltip>
            </div>
            </div>
                <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">AV_C</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].AV_C" placement="top">
              <div class="num" style="color:#1662ec" @click="tocomment('AV_C', '#1662ec',7)"> {{ infoList[node].AV_C }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text" >AV_D</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].AV_D" placement="top">
              <div class="num" style="color:#FFC0CB" @click="tocomment('AV_D', '#FFC0CB',8)"> {{ infoList[node].AV_D}}</div>
               </el-tooltip>
            </div>
            </div>
            </div>
             <div class="row1-top row1-top1" style="margin-top:1vh">
                 <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">  TC</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].TC" placement="top">
              <div class="num" @click="toIpt(infoList[node].TC, '扰动观测器采样时间', 'TC', node)"> {{ infoList[node].TC }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
         <div class="text">GC1</div>
          <el-tooltip class="item" effect="dark" :content="infoList[node].GC1" placement="top">
               <div class="num" @click="toIpt(infoList[node].GC1, '扰动观测器静态作用', 'GC1', node)"> {{ infoList[node].GC1 }}</div>
                </el-tooltip>
            </div>
            </div>
               <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">TZ</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].TZ" placement="top">
               <div class="num" @click="toIpt(infoList[node].TZ, '扰动观测器专家时间', 'TZ', node)"> {{ infoList[node].TZ }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
         <div class="text">GC2</div>
          <el-tooltip class="item" effect="dark" :content="infoList[node].GC2" placement="top">
               <div class="num" @click="toIpt(infoList[node].GC2, '扰动观测器动态作用', 'GC1', node)"> {{ infoList[node].GC2 }}</div>
                </el-tooltip>
            </div>
            </div>
                <div class="row-couml flex">
             <div class="list-num flex">
                <div class="text">OUTL</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].OUTL" placement="top">
               <div class="num" @click="toIpt(infoList[node].OUTL, '扰动观测器输出下限，先控模块并联时限制', 'OUTL', node)"> {{ infoList[node].OUTL }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
         <div class="text">OUTH</div>
          <el-tooltip class="item" effect="dark" :content="infoList[node].OUTH" placement="top">
               <div class="num" @click="toIpt(infoList[node].OUTH, '扰动观测器输出下限，先控模块并联时限制', 'OUTH', node)"> {{ infoList[node].OUTH }}</div>
                </el-tooltip>
            </div>
            </div>
            </div>
          </div>
           <div class="row row-5">
            <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">TC</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].TC_CD" placement="top">
              <div class="num" @click="toIpt(infoList[node].TC_CD, '重叠控制采样时间', 'TC_CD', node)"> {{ infoList[node].TC_CD }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">CDGD</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].CD_GD" placement="top">
              <div class="num" @click="toIpt(infoList[node].CD_GD, '重叠控制作用拐点', 'CD_GD', node)"> {{ infoList[node].CD_GD }}</div>
               </el-tooltip>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">TZ</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].TZ_CD" placement="top">
              <div class="num" @click="toIpt(infoList[node].TZ_CD, '重叠控制专家时间', 'TZ_CD', node)"> {{ infoList[node].TZ_CD }}</div>
              </el-tooltip>
            </div>
             <div class="list-num list-num1 flex">
                <div class="text">CDKJ</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].CD_K_J" placement="top">
              <div class="num" @click="toIpt(infoList[node].CD_K_J, '重叠控制，扰动观测器静态系数', 'CD_K_J', node)"> {{ infoList[node].CD_K_J }}</div>
              </el-tooltip>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">CDK1</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].CD_K_FD" placement="top">
              <div class="num" @click="toIpt(infoList[node].CD_K_FD, '重叠控制，输出幅度系数', 'CD_K_FD', node)"> {{ infoList[node].CD_K_FD }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">CDKD</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].CD_K_D" placement="top">
              <div class="num" @click="toIpt(infoList[node].CD_K_D, '重叠控制，扰动观测器动态系数', 'CD_K_D', node)"> {{ infoList[node].CD_K_D }}</div>
              </el-tooltip>
            </div>
            </div>
              <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">CDK2</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].CD_K" placement="top">
              <div class="num" @click="toIpt(infoList[node].CD_K, '重叠控制输出保留系数', 'CD_K', node)"> {{ infoList[node].CD_K }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">CDBH</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].CD_BH" placement="top">
              <div class="num" @click="toCompon(2, 'CD_BH', node, 'CD_BH_WF')"> {{ infoList[node].CD_BH }}</div>
               </el-tooltip>
            </div>
            </div>
               <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">CDL</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].CDL" placement="top">
              <div class="num" @click="toIpt(infoList[node].CDL, '重叠控制输出下限', 'CDL', node)"> {{ infoList[node].CDL }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">CDH</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].CDH" placement="top">
              <div class="num" @click="toIpt(infoList[node].CDH, '重叠控制输出上限', 'CDH', node)"> {{ infoList[node].CDH }}</div>
                </el-tooltip>
            </div>
            </div>
          </div>
            <div class="row">
            <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">XGL1H</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].XGL1_H" placement="top">
              <div class="num" @click="toIpt(infoList[node].XGL1_H, '自适应相关变量1上限', 'XGL1_H', node)"> {{ infoList[node].XGL1_H }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">XGL1L</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].XGL1_L" placement="top">
              <div class="num" @click="toIpt(infoList[node].XGL1_L, '自适应相关变量1下限', 'XGL1_L', node)"> {{ infoList[node].XGL1_L }}</div>
              </el-tooltip>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">XGL2H</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].XGL2_H" placement="top">
              <div class="num" @click="toIpt(infoList[node].XGL2_H, '自适应相关变量2上限', 'XGL2_H', node)"> {{ infoList[node].XGL2_H }}</div>
              </el-tooltip>
            </div>
             <div class="list-num list-num1 flex">
                <div class="text">XGL2L</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].XGL2_L" placement="top">
              <div class="num" @click="toIpt(infoList[node].XGL2_L, '自适应相关变量2下限', 'XGL2_L', node)"> {{ infoList[node].XGL2_L }}</div>
               </el-tooltip>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">PVDI</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].PVDI" placement="top">
              <div class="num" @click="toIpt(infoList[node].PVDI, '过程测量值判断死区', 'PVDI', node)"> {{ infoList[node].PVDI }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text">UVDI</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].UVDI" placement="top">
              <div class="num" @click="toIpt(infoList[node].UVDI, '控制量判断死区', 'UVDI', node)"> {{ infoList[node].UVDI }}</div>
               </el-tooltip>
            </div>
            </div>
              <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">EKDB</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].EKBDFDSP" placement="top">
              <div class="num" @click="toIpt(infoList[node].EKBDFDSP, '自适应起作用偏差死区', 'EKBDFDSP', node)"> {{ infoList[node].EKBDFDSP }}</div>
                </el-tooltip>
            </div>
            </div>
          </div>
           <div class="row2 flex">
            <div class="list1">
              <div class="list-tit">TL1</div>
               <div  class="data1-ipt" @click="toIpt(infoList[node].TL1,'软伺服作用时间，下位已经全部将4个作用时间参数统一，只需修改TL1','TL1', node)">
                {{ infoList[node].TL1 }}
              </div>
              <div class="data1-ipt">
                {{ infoList[node].TL2 }}
              </div>
              <div class="data1-ipt">
                {{ infoList[node].TL3 }}
              </div>
              <div class="data1-ipt">
                {{ infoList[node].TL4 }}
              </div>
            </div>
            <div class="list1">
              <div class="list-tit">E1</div>
                 <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E1, '软伺服偏差区间1', 'E1', node)"
              >
                {{ infoList[node].E1 }}
              </div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E2, '软伺服偏差区间2', 'E2', node)"
              >
                {{ infoList[node].E2 }}
              </div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E3, '软伺服偏差区间3', 'E3', node)"
              >
                {{ infoList[node].E3 }}
              </div>
              <div
                class="data1-ipt"
                @click="toIpt(infoList[node].E4, '软伺服偏差区间4', 'E4', node)"
              >
                {{ infoList[node].E4 }}
              </div>
            </div>
            <div class="list1">
              <div class="list-tit">AO1</div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO1,
                    '软伺服偏差区间1对应输出幅度1',
                    'AO1',
                    node
                  )
                "
              >
                {{ infoList[node].AO1 }}
              </div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO2,
                    '软伺服偏差区间2对应输出幅度2',
                    'AO2',
                    node
                  )
                "
              >
                {{ infoList[node].AO2 }}
              </div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO3,
                    '软伺服偏差区间3对应输出幅度3',
                    'AO3',
                    node
                  )
                "
              >
                {{ infoList[node].AO3 }}
              </div>
              <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].AO4,
                    '软伺服偏差区间4对应输出幅度4',
                    'AO4',
                    node
                  )
                "
              >
                {{ infoList[node].AO4 }}
              </div>
            
            </div>
              <div class="list1">
                 <div class="list-tit">TL</div>
                 <div
                class="data1-ipt"
                @click="
                  toIpt(
                    infoList[node].TL,
                    '防抖时间，同时对软伺服和重叠控制起作用',
                    'TL',
                    node
                  )
                "
              >
                {{ infoList[node].TL }}
              </div>
                <div class="list-tit">BLK</div>
                  <div
                class="data1-ipt"
                @click="
                  toIpt(infoList[node].ZF_K, '软伺服保留系数', 'ZF_K', node)
                "
              >
                {{ infoList[node].ZF_K }}
              </div>
              </div>
           </div>
           <div class="row3 row flex align-cen justify-content-cen">
            <div class="box">
                <div class="row-couml flex">
            <div class="list-num flex ">
                <div class="text">PO</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].PT" placement="top">
              <div class="num" @click="toIpt(infoList[node].PT, '基本比例参数', 'PT', node)"> {{ infoList[node].PT }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text text1">KP</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].KP" placement="top">
              <div class="num" @click="toIpt(infoList[node].KP, '变比例系数，与SP和PV的差值有关', 'KP', node)"> {{ infoList[node].KP }}</div>
                </el-tooltip>
            </div>
             <div class="list-num flex ">
                <div class="text">P</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].PX" placement="top">
              <div class="num" 
              @click="toCompon(2, 'PX', node, 'PX_WF')"
              > {{ infoList[node].PX }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text text1">SVH</div>
                <el-tooltip class="item" effect="dark" :content="infoList[node].SVH" placement="top">
              <div class="num" @click="toIpt(infoList[node].SVH, '变积分起作用阀值，该值为PVMU-PVMD的百分比', 'SVH', node)"> {{ infoList[node].SVH }}</div>
                 </el-tooltip>
            </div>
            </div>
                <div class="row-couml flex">
            <div class="list-num flex ">
                <div class="text">TIO</div>
                   <el-tooltip class="item" effect="dark" :content="infoList[node].TI" placement="top">
              <div class="num" @click="toIpt(infoList[node].TI, '基本积分系数', 'TI', node)"> {{ infoList[node].TI }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text text1">KI</div>
                   <el-tooltip class="item" effect="dark" :content="infoList[node].KI" placement="top">
              <div class="num" @click="toIpt(infoList[node].KI, '变积分系数，该值与SP和PV的差值有关', 'KI', node)"> {{ infoList[node].KI }}</div>
               </el-tooltip>
            </div>
             <div class="list-num flex ">
                <div class="text">TI</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].TIi" placement="top">
              <div class="num" @click="toCompon(2, 'TIi', node, 'TIi_WF')"> {{ infoList[node].TIi }}</div>
               </el-tooltip>
            </div>
            <div class="list-num1"></div>
            </div>
               <div class="row-couml flex">
            <div class="list-num flex ">
                <div class="text">TD</div>
                  <el-tooltip class="item" effect="dark" :content="infoList[node].TD" placement="top">
              <div class="num" @click="toIpt(infoList[node].TD, '基本微分系数', 'TD', node)"> {{ infoList[node].TD }}</div>
                </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text text1">KD</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].KD" placement="top">
              <div class="num" @click="toIpt(infoList[node].KD, '变微分系数，该值与SP和PV的差值有关', 'KD', node)"> {{ infoList[node].KD }}</div>
               </el-tooltip>
            </div>
             <div class="list-num flex ">
                <div class="text">DI</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].DI" placement="top">
              <div class="num" @click="toIpt(infoList[node].DI, '最终起作用的微分系数', 'DI', node)"> {{ infoList[node].DI }}</div>
               </el-tooltip>
            </div>
             <div class="list-num  list-num1 flex">
                <div class="text text1">SPRH</div>
                 <el-tooltip class="item" effect="dark" :content="infoList[node].SP_RH" placement="top">
              <div class="num" @click="toIpt(infoList[node].SP_RH, 'SP速率限幅，该值为PVMU-PVMD的百分比', 'SP_RH', node)"> {{ infoList[node].SP_RH }}</div>
                </el-tooltip>
            </div>
            </div>
            </div>
           </div>
           <div class="row4 flex align-cen">
             <div class="time-tit">指定时间：</div>
              <el-date-picker
              v-model="value2"
              type="datetimerange"
              :picker-options="pickerOptions"
             range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
           popper-class = "dataleft"

              >
            </el-date-picker>
            <div class="row4-btn" @click="sech">查询</div>
           </div>
        </div>
        <div class="fristcon-bot-row2 flex align-cen">
          <div class="fristcon-bot-row2-left1 flex">
              <div
          @click="
            toIpt(infoList[node].PCMMS== 0 ? false : true , '点击按钮', 'PCMMS', node)
          "
           :style="{
              background:infoList[node].PCMMS == 0 ?'#0ef7ff': '#25495e',
            }"
        >
          {{ infoList[node].PCMMS == 0 ? "并联" : "串联" }}
        </div>
        <div @click="toIpt(infoList[node].RSFEN, '点击按钮', 'RSFEN', node)"
        :style="{
              background:infoList[node].RSFEN =='RSF投用' ?'#0ef7ff': '#25495e',
            }">
          {{ infoList[node].RSFEN ? "RSF投用" : "RSF切除" }}
        </div>
        
        <div @click="toIpt(infoList[node].GCEN, '点击按钮', 'GCEN', node)"
        :style="{
              background:infoList[node].GCEN  =='GC投用' ?'#0ef7ff': '#25495e',
            }">
          {{ infoList[node].GCEN ? "GC投用" : "GC切除" }}
        </div>
        <div @click="toIpt(infoList[node].CDEN, '点击按钮', 'CDEN', node)"
        :style="{
              background:infoList[node].CDEN  =='CD投用' ?'#0ef7ff': '#25495e',
            }">
          {{ infoList[node].CDEN ? "CD投用" : "CD切除" }}
        </div>
        <div @click="toIpt(infoList[node].XZEN, '点击按钮', 'XZEN', node)"
        :style="{
              background:infoList[node].XZEN  =='XZ投用' ?'#0ef7ff': '#25495e',
            }">
          {{ infoList[node].XZEN ? "XZ投用" : "XZ切除" }}
        </div>
        <div
        :style="{background: iscolostatus == 1 ? '#0ef7ff' : '#25495e'}"
          @click="
            chanereast(
              infoList[node].ZSYZZD && infoList[node].ZSYZZD == 1 ? '254' : '1',
              '是否改变自整定功能的选择',
              'ZSYZZD',
              node,
              1
            )
          "
        >
          自整定
        </div>
        <div
        :style="{background: iscolostatus == 2 ? '#0ef7ff' : '#25495e'}"
          @click="
            chanereast(
              infoList[node].ZSYZZD && infoList[node].ZSYZZD == 1 ? '253' : '2',
              '是否改变自适应功能的选择？',
              'ZSYZZD',
              node,
              2
            )
          "
        >
          自适应
        </div>
          </div>
          <div class="fristcon-bot-row2-left2">
              <div
              class="list-flex1-btn"
              :style="{color: iscolostatus == 3 ? '#0ef7ff' : ''}"
              @click="
                chanereast(
                  infoList[node].RESET,
                  '确定要初始化自整定和自适应的参数吗',
                  'RESET',
                  node,
                  3
                )
              "
            >
              参数复位
            </div>
              <div
              class="list-flex1-btn list-flex1-btn1"
               :style="{color: iscolostatus == 4 ? '#0ef7ff' : ''}"
              @click="
                chanereast(
                  infoList[node].ZZDMAN,
                  '确定要提取自整定参数吗？',
                  'ZZDMAN',
                  node,
                  4
                )
              "
            >
              参数提取
            </div>
          </div>
             <div class="condition-time ma-auto flex">
        <div @click="serchHis(1,0)" :class="{current:active == 0}">1h</div>
        <div @click="serchHis(2,1)" :class="{current:active == 1}">2h</div>
        <div @click="serchHis(4,2)" :class="{current:active == 2}">4h</div>
        <div @click="serchHis(8,3)" :class="{current:active == 3}">8h</div>
        <div @click="serchHis(24,4)" :class="{current:active == 4}">24h</div>
        <div @click="serchHis(72,5)" :class="{current:active == 5}">72h</div>
      </div>
        </div>
      </div>
      <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
        <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="Hhistoryname"
      :node="Hnode"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    </div>
</template>
<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historical";
import { downAction } from "@/api/index/index";
import index from "./_index/index.js";
 export default {
  components: { Historical, inputVal },
     props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data() {
      return {
      addnember:'',
      iscolostatus:'',
      charAtlet:'',
      echarShow:[true,true,true,true,true,true,true,true,true],
      dataview:{},
      Lkname:'',
      Hhistoryname:'',
      Hnode:'',
      isHshow:false,
      historyname:'',
      node:'',
      titname:'',
       xAxis: [],
      echartData: [],
      legendData: [],
      timer: null,
      myChart: "",
      loading: true,
      echartcolor: [],
      end_time: "",
      start_time: "",
      scaleHeight: "",
      name: [],
      name1: [],
      mark: [],
      nodeList: [],
      option:'',
      value2: '',
      havetrue:'',
      current:'current',
      active:10,
      now:true,
      dtname:'实时趋势',
      projectData:{}
      }
    },
    mixins: [index],
      watch: {
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.loading = false;
            this.myChart.setOption(newVal, true);
          } else {
            this.loading = false;
            this.myChart.setOption(oldVal);
          }
        } else {
          this.loading = false;
          this.drawLine();
        }
      },
    },
      rightarr1: {
      handler(newVal, oldVal) {
    
        if (this.rightarr) {
          if (newVal) {
            this.rightarr(newVal, true);
          } else {
            this.rightarr(oldVal);
          }
        }
      },
    },
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    // rightarr
  },
    created(){
    },
    mounted(){
      this.getInfo();
      this.timer = setInterval(() => {
              setTimeout(this.getInfo(), 0);
            }, 5000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {           
                clearInterval(this.timer);                                    
            })
      if(this.value2){
        this.havetrue = '至'
      }
    },
    methods: {
        toCompon(key, name, name2, name3) {
          
      this.isIndex = key;
      this.Hhistoryname = name;
      this.Hnode = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (this.isMshow = true);
        case 1:
          return (this.isFshow = true);
        case 2:
          return (this.isHshow = true);
      }
    },
       isClose1() {
      this.isHshow = false;
    },
      // 时间段查询
      sech(){
        this.start_time = this.value2[0];
        this.end_time = this.value2[1];
        this.getInfo();
      },
      // 历史实时
      serchDt(index){
        this.now = !this.now
        if(this.now ){
           this.active = index
          this.dtname = '实时趋势'
          this.start_time = ""
             this.timer = setInterval(() => {
              setTimeout(this.getInfo(), 0);
            }, 5000);
        }else{
           this.active = '-1'
          this.dtname = '历史趋势'
          this.getInfo()
           clearInterval(this.timer);
        }
      },
        chanereast(val, name, mark, node,index) {
      this.$confirm(name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (typeof val == "boolean") {
            val = !val;
          }
          downAction({
            device: this.projectData.device,
            mark: mark,
            node: node,
            value: val,
            cp_type: this.projectData.type,
          })
            .then((res) => {
              if (res.info.res == 1) {
                this.iscolostatus = index
                this.infoList[node].mark = res.item.value
                this.$message.success('数据正在下置，请勿重复操作！');
              } else {
                this.$message.error("出错了");
              }
            })
            .catch((err) => {
              this.$message.error("下置失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
        //  查看过去趋势
    serchHis(val,i,type) {
      if(!this.now){
      //  this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
      // this.start_time = this.$util.getTimeData(val);
      // this.active = i
      // this.getInfo();
        this.value2 = []
             if(type == 'down'){
          if(this.start_time ==""){
            this.$message.warning('已经是最新的了～～');
            return
          }else{
          this.addnember = this.addnember >= 1 ? this.addnember - val : 0
          if(this.addnember != 0){
          this.start_time = this.$util.getTimeData(this.addnember) ;
          this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
          }else{
            this.end_time = "";
          this.start_time = ""
          }
          this.active = i
          }
        }else if(type == 'up'){
          if(this.start_time){
             this.addnember = this.addnember + val
          }else{
            this.addnember = val 
          }
          this.start_time = this.start_time ?  this.$util.getTimeData(this.addnember) : this.$util.getTimeData(val);
          this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
          this.active = i
        }else{
          this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
          this.start_time = this.$util.getTimeData(val);
          this.active = i
        }
        this.getInfo();
      }else{
        this.$message.error("请先切换为历史趋势");
      }
    },
          // 自动手动跟踪
    chaneStu(val, mark, node) {
      downAction({
        device: this.projectData.device,
        mark: mark,
        node: node,
        value: val,
        cp_type: this.projectData.type,
      })
        .then((res) => {

          if (res.info.res == 1) {
            this.infoList[node].RM = res.item.value
          } else {
          }
        })
        .catch((err) => {});
    },
        // 获取输入框组件传过来的值
       DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
       //    打开输入框组件。
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    },
    destroyed() {
        // sessionStorage.removeItem('infoList')
    },
  }
</script>
<style lang="scss" scoped>
.addtable{
  table{
    margin: 0 10px;
   
  }
}
.el-main{
  width: 100% !important;
}
.current{
  color: #0ef7ff !important;
  border: 1px solid #0ef7ff;
  box-sizing: border-box;
}
.fristcon{
  width: 100vw;
  height: 92vh;
  overflow: hidden;
  background-image: linear-gradient(
		#042643, 
		#042643), 
	linear-gradient(0deg, 
		#0b385f 3%, 
		#064468 30%, 
		#005071 57%, 
		#045373 74%, 
		#085575 100%);
	background-blend-mode: normal, 
		normal;
  .fristcon-tit{
    width: 90vw;
			height: 6vh;
			background: url("~@/assets/images/ding1.png") no-repeat center center;
			background-size: 100% 100%;
			margin:0vh 2vw;
			text-align: center;
			line-height: 5vh;
			color: #0ef7ff;
			font-size: 1.5vw;
  }
  .fristcon-cen {
    .fristcon-cen-left{
      width: 11vw;
       height: 56vh;
      background: rgba(0,22,36,.2);
      // background-image: linear-gradient(to right, rgba(0,22,36,.6) , rgba(0,22,36,.2));
      .fristcon-cen-left-tit{
        width:11vw;
        height: 4vh;
        text-align: center;
        color: #22465b;
        font-size: .8vw;
        background-color: #fff272;
        line-height: 4vh;
      }
      .btnarr{
        margin-top: 4vh;
      .fristcon-cen-left-btn{
              width: 7vw;
              margin: 0 auto;
              height: 6vh;
                background: url("~@/assets/images/xk2.png") no-repeat center center;
              background-size: 100% 100%;
              margin-top: 3vh;
             
              .text{
                color: #6acafb;
                font-size: 2vh;
                line-height: 3vh;
                padding-left: 1vw;
                 cursor: pointer;
              }
              .num{
                color: #eafeff;
                font-size: 1vw;
                padding-right: 0.5vw;
                 cursor: pointer;
                
              }
            }
            }
            .btnarr1{
              margin-top: 4vh;
              .btnarr1-btn{
                width: 4vw;
                background: rgba(14,247,255,.2);
                height: 3vh;
                text-align: center;
                line-height: 3vh;
                color: #0ef7ff;
                font-size: 1vw;
                margin: 0 auto;
                margin-top: 1vh;
                border-radius: 2px;
                 cursor: pointer;
              }
            }
      
    }
    .fristcon-cen-cen{
  width: 76vw;
      height: 56vh;
    }
    #rts_Chart{
    width: 100%;
    height: 100%;
    }
    .fristcon-cen-bot{
      width: 13vw;
      height: 56vh;
      background: rgba(0,22,36,.2);
      .typedata{
        width: 11vw;
        height: inherit;
        margin-left: 2vw;
        margin-top: 1vh;
       .typedatabox{
         div{
           	font-family: 'MicrosoftYaHei';
	            font-size: 1vh;
              line-height: 2vh;
         }
       }
      }
    }
  }
  .fristcon-cen-btn{
    width: 100%;
    .btn-f{
      width: 37vw;
      height: 3vh;
      margin: 1vh auto;
      .btn-f-btn{
        width: 5vw;
        background: rgba(14, 247, 255, 0.2);
        border-radius: 2px;
        color: rgba(14, 247, 255, 0.5);
        text-align: center;
        line-height: 3vh;
        font-size: 1vw;
        margin-left: 3vw;
         cursor: pointer;
      }
      .btn-f-btn:first-child{
        margin-left: 0;
       
      }
    }
  }
  .fristcon-bot{
    width: 97vw;
    margin: 0 auto;
    
    .fristcon-bot-row1{
      position: relative;
      .row{
        width: 13vw;
        height: 19vh;
        background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
         margin-left: 1vw;
        .row-couml{
          padding: 0 0.8vw;
          line-height: 4.5vh;
          .list-num{
            font-family: 'ArialNarrow';
            width: 50%;
            
            .text{
              color: #6acafb;
            font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
            .num{
              color: rgba(228,254,255,.7);
              font-size: 1.5vh;
              padding-left: 1vw;
              cursor: pointer;
                width: 3vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
            }
          }
          .list-num1{
            width: 50%;
              .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
          }
        }
        .row-couml:first-child{
          padding-top: 0.8vh;
        }
      }
      .row:first-child{
        margin-left: 0;
      }
      .row1{
        width: 13vw;
        height: 19vh;
        margin-left: 1vw;
        .row1-top{
          width: 100%;
          height: 9vh;
           background: url("~@/assets/images/xk1.png") no-repeat center center;
        background-size: 100% 100%;
           .row-couml{
          padding: 0 0.8vw;
          line-height: 3vh;
             .list-num{
            font-family: 'ArialNarrow';
            width: 50%;
            
            .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
            .num{
              color: #e4feff;
               font-size: 1.5vh;
              padding-left: 1vw;
              cursor: pointer;
              width: 3vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
            }
          }
          .list-num1{
            width: 50%;
              .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
          }
        }
       
        }
                .row1-top1{
           .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 2vw;
            text-align: right;
            line-height: 3vh;
            }
        }
      }
      .row2{
        width: 13vw;
        height: 19vh;
        margin-left: 1vw;
          background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
        .list1{
          width: 25%;
          text-align: center;
          padding-top: 2vh;
          line-height: 3vh;
          div{
            font-size: 1.5vh;
            color: #e4feff;
             cursor: pointer;
          }
          .list-tit{
             color: #6acafb;
             
          }
        }
      }
      .row-5{
        .row-couml{
          line-height: 3.5vh;
        }
      }
      .row3{
        width: 27vw;
        height: 15vh;
         background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
        margin-left: auto;
     
        .list-num{
          width: auto !important;
        }
        .text{
          padding-left: 1vw;
        }
      }
      .row4{
        position: absolute;
        right: 0;
        bottom: 0;
        .time-tit{
          color: #0c78a3;
          font-size: 1vw;
        }
        .row4-btn{
          width: 4vw;
          height: 3vh;
           background: rgba(14, 247, 255, 0.2);
           border-radius: 2px;
          color: rgba(14, 247, 255, 0.5);
          text-align: center;
          line-height: 3vh;
          font-size: 1vw;
          margin-left: 10px;
        }
        .el-range-editor.el-input__inner{
          background: #031d30 !important;
          border: 1px solid #064a6d;
          width: 16vw;
          height: 3vh;
         padding: 3px 5px;
         position: relative;
         z-index: 1;
        }
       
      }
    }
    .fristcon-bot-row2{
      position: relative;
      margin: 1vw auto;
      .fristcon-bot-row2-left1 {
        width: 28vw;
        background-color: #031d30;
        div{
          color: #397ca3;
          height: 3vh;
          line-height: 3vh;
          font-size: 1.5vh;
          width: 4vw;
          text-align: center;
          position: relative;
        }
        div::after{
           content: '';
           position: absolute;
           height: 1.4vh;
           width: 1px;
           background: #397ca3;
           right: 0;
           top: 0.8vh;
        }
        div:last-child::after{
          content: none;
        }
      }
      .fristcon-bot-row2-left2{
        width: 42vw;
        height: 1vh;
        background: url("~@/assets/images/bot.png") no-repeat center center;
        background-size: 100% 100%;
        margin-left: 5vw;
        .list-flex1-btn{
         width: 5vw;
         height: 3vh;
          background-color: rgba(14,247,255,.2);
          border-radius: 2px;
        line-height: 3vh;
        text-align: center;
        cursor: pointer;
        font-size: 1vw;
        color: #209caf;
        margin-left: 15.6vw;
        margin-top: -0.8vh;
        }
        .list-flex1-btn1{
          margin-left: 21.4vw;
          margin-top: -3vh;
        }
      }
       .condition-time {
        
      div {
        width: 2vw;
        height: 3vh;
        background-color: rgba(14,247,255,.2);
        border-radius: 2px;
        line-height: 3vh;
        text-align: center;
        font-size: 1vw;
        color: #209caf;
        margin-right: 3px;
        cursor: pointer;
      }
    }
    }
  }

}

::v-deep{
    .el-picker-panel{
            left: 542px !important;
            padding: 1vh;
          }
  .el-range__icon,.el-range__close-icon{
            line-height: 2vh !important;
          }
          .el-range__close-icon{position: absolute;
          z-index: 2;
          right: 0;
          }
          .el-range__icon{display: none;}
          .el-range-input{
             background: #031d30 !important;
             color: #fff;
             width: 42%;
             font-size: .6vw;
          }
          .el-range-separator{color: #fff;line-height: 2vh;}
          
}
</style>
<style lang="scss">
 .el-time-panel{
            left: -50px !important;
           
          }
</style>